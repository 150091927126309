import * as yup from 'yup';

export const numberRegex = /^\d+$/;
export const phoneNumberRegex = /^(\d{9,9})*$/;
export const passwordRegex =
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])){8,24}/;

export const phoneNumberTemplate = (): yup.StringSchema =>
  yup.string().matches(phoneNumberRegex, 'validator.phone');

export const email = (): yup.StringSchema =>
  yup
    .string()
    .min(6, 'validator.emailLength')
    .max(32, 'validator.emailLength')
    .email('validator.emailFormat')
    .trim();

export const passwordTemplate = (): yup.StringSchema =>
  yup
    .string()
    .min(8, 'validator.passwordLength')
    .max(24, 'validator.passwordLength')
    // .matches(passwordRegex, 'auth.error.passwordFormat')
    .trim();

export const name = (): yup.StringSchema =>
  yup
    .string()
    .required('validator.required')
    .max(64, 'validator.nameLength')
    .trim();

export const required = (): yup.StringSchema =>
  yup.string().required('validator.required').trim();
export const requiredNumber = (): yup.NumberSchema =>
  yup.number().required('validator.required');
